import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(12, 0),
      textAlign: 'center',
    },
    copyContainer: {
      '& h2': {
        maxWidth: '670px',
        color: theme.palette.success.main,
        lineHeight: '60px',
        [theme.breakpoints.down('sm')]: {
          lineHeight: '46px',
        },
      },
      '& p': {
        maxWidth: '590px',
        margin: theme.spacing(4, 'auto'),
        fontSize: '20px',
      },
    },
    imgContainer: {
      padding: theme.spacing(0, 3),
      margin: theme.spacing(12, 'auto', 0),
      width: '100%',
      maxWidth: '900px',
    },
  }),
);
