import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Quote from '../../../components/Quote';

const OnlineSolution: React.FC = () => {
  const classes = useStyles();
  return (
    <Container component="section" className={classes.root} maxWidth="lg">
      <div className={classes.headerTextContainer}>
        <Typography variant="h2">Shop for solar energy without annoying sales tactics using our secure online portal</Typography>
        <Typography variant="body1">
          We were frustrated with the lack of transparency, overly aggressive salespeople, and the complicated processes
          used in the Solar industry... So we cut out sales people altogether and streamlined the process. It&apos;s the
          newest way to go solar.
        </Typography>
      </div>

      <div className={classes.quoteContainer}>
        <Quote
          withBG
          quote="Absolutely fabulous! Beautiful system. Solvana
gave us excellent service and communication. We are
very excited about our solar setup... Solvana held our
hand and gave us incredible support the entire way.
Hands down the best company and service we found
after much research and consults with other solar
companies. They are simply the best."
          authorName="The Haines Family"
          authorLocation="New Jersey"
        />
      </div>
    </Container>
  );
};

export default OnlineSolution;
