import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import { RiShieldCheckLine } from 'react-icons/all';
import { useStyles } from '../common/styles';

const StepOne: React.FC = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-request-a-solar-proposal.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.copyContainer}>
        <div className={classes.leftContainer}>
          <RiShieldCheckLine size={24} />
        </div>
        <div>
          <Typography variant="h6">
            Step one: Signup & See your potential savings by requesting your Free digital solar proposal
          </Typography>
          <Typography variant="body1">
            We lay out everything you need to know about transitioning to solar energy with us. We provide you with a
            quote within 48 hours of your request detailing your cost savings and solar recommendations.
          </Typography>
        </div>
      </div>

      <div className={classes.rightContainer}>
        <Img  fluid={data.image.childImageSharp.fluid} />
      </div>
    </div>
  );
};

export default StepOne;
