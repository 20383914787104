import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12, 3),
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.dark : theme.palette.success.main,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(8, 1.5),
      },
    },
    container: {
      padding: theme.spacing(0, 0, 0),
      '& ul': {
        margin: 0,
        padding: 0,
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 0, 2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 1),
      },
    },
    threeStepsTitle: {
      color: '#fff',
      maxWidth: '400px',
      textAlign: 'left',
      lineHeight: '60px',
      marginBottom: theme.spacing(5),
      alignSelf: 'flex-start',
    },
  }),
);
