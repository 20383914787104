import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(6, 0, 12, 3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 0, 0),
        flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    leftContainer: {
      padding: theme.spacing(3),
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingBottom: theme.spacing(6),
      },
    },
    leftInnerContainer: {
      maxWidth: '655px',
      margin: '0 auto',
      '& h2': {
        fontSize: '32px',
        marginBottom: theme.spacing(2),
        color: theme.palette.type === 'dark' ? Colors.GreyFour : theme.palette.secondary.main,
      },
      '& p': {
        fontSize: '20px',
      },
      '& ul': {
        margin: theme.spacing(5, 0),
        padding: 0,
        '& li': {
          margin: theme.spacing(0, 0, 2),
          padding: 0,
          '& h4': {
            fontSize: '24px',
            marginLeft: theme.spacing(2),
          },
        },
      },
    },
    rightContainer: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }),
);
