import React from 'react';
import HomePage from '../containers/HomePage';
import Layout from '../containers/Layout';

const IndexPage: React.FC = () => (
  <Layout
    title="Easy Solar Energy Installation for Your Home & Business | Solvana"
    withoutMarginTop
    withTransparentHeaderBg
    canonicalLink="https://www.solvana.io/"
  >
    <HomePage />
  </Layout>
);

export default IndexPage;
