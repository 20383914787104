import React from 'react';
import OnlineSolution from './OnlineSolution';
import Hero from './Hero';
import LearnMoreCTA from './LearnMoreCTA';
import ThreeSteps from './ThreeSteps';
import CustomerTrust from './CustomerTrust';

const HomePage: React.FC = () => {
  return (
    <div>
      <Hero />
      <OnlineSolution />
      <ThreeSteps />
      <LearnMoreCTA />
      <CustomerTrust />
    </div>
  );
};

export default HomePage;
