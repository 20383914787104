import React from 'react';
import { Container, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Routes } from '../../../utils/Routes';
import SuccessButton from '../../../components/SuccessButton';
import { InverterSolarEdge, InverterSolArk, SolarRoof, Squirrel } from '../../../components/SolvanaIcons/RFA';

const CustomerTrust: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-happy-couple-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container component="section" className={classes.root} maxWidth="xl">
      <div className={classes.leftContainer}>
        <div className={classes.leftInnerContainer}>
          <Typography variant="h2">
            Hundreds of home-owners in Florida, Massachusetts, and New Jersey trusted us to help with their transition
            to solar energy.
          </Typography>
          <Typography variant="body1">
            We want to make your process to get solar power as easy as possible, too, so we’ll set up your new solar
            system, manage the process, and install everything you need to go solar, including:
          </Typography>

          <List>
            <ListItem>
              <SolarRoof width="42px" />
              <Typography variant="h4">Panels</Typography>
            </ListItem>
            <ListItem>
              <InverterSolarEdge width="42px" />
              <Typography variant="h4">Inverters</Typography>
            </ListItem>
            <ListItem>
              <InverterSolArk width="42px" />
              <Typography variant="h4">Batteries</Typography>
            </ListItem>
            <ListItem>
              <Squirrel width="42px" />
              <Typography variant="h4">Accessories</Typography>
            </ListItem>
          </List>

          <SuccessButton title="View All Solar Equipment" size="large" href={Routes.HowItWorks} />
        </div>
      </div>

      <div className={classes.rightContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </Container>
  );
};

export default CustomerTrust;
