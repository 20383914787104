import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import StyledButton from '../../../components/StyledButton';
import { useDispatch } from 'react-redux';
import { setCurrentStep, setShowRFA } from '../../RFA/actions';

const Hero: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setShowRFA(true));
    dispatch(setCurrentStep(0));
  };

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-solar-roof-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.topContainer}>
          <div className={classes.innerContainer}>
            <hgroup>
              <Typography color="primary" variant="h1">
                The easiest way
                <br />
                to get solar energy is here.
              </Typography>
              <Typography className={classes.subtitle} variant="h2">
                Residential and commercial solar panel installation for Florida, Massachusetts, New Jersey, and Texas
              </Typography>
            </hgroup>
            <StyledButton
              size="large"
              className={classes.button}
              onClick={handleClick}
              variant="contained"
              color="primary"
              type="button"
            >
              Request a Free Proposal
            </StyledButton>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <Img fluid={data.image.childImageSharp.fluid} />
        </div>
      </div>
    </>
  );
};

export default Hero;
