import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      padding: theme.spacing(6, 3, 12),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(6, 3),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(6, 2),
      },
    },
    headerTextContainer: {
      '& h2': {
        fontSize: '32px',
        marginBottom: theme.spacing(2),
        color: theme.palette.type === 'dark' ? Colors.GreyFour : theme.palette.secondary.main,
      },
      '& p': {
        maxWidth: '515px',
        margin: '0 auto',
      },
      maxWidth: '600px',
      marginBottom: theme.spacing(8),
    },
    quoteContainer: {
      maxWidth: '800px',
    },
  }),
);
