import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.dark : theme.palette.success.main,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(6, 0, 0),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 0, 0),
      },
      '& img': {
        width: '100%',
      },
      '& h6': {
        color: '#fff',
        fontWeight: 900,
      },
      '& p': {
        color: '#fff',
        marginBottom: theme.spacing(1),
      },
      '& svg': {
        fill: '#fff',
      },
    },
    copyContainer: {
      maxWidth: '500px',
      textAlign: 'left',
      margin: theme.spacing(0, 'auto', 4, 'auto'),
      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('sm')]: {

        padding: theme.spacing(0, 2),
      },
      display: 'flex',
    },
    leftContainer: {
      padding: theme.spacing(0.5, 1, 0, 0),
    },
    rightContainer: {
      padding: theme.spacing(0, 6),
    },
  }),
);
