import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import { RiShieldCheckLine } from 'react-icons/all';
import { useStyles } from '../common/styles';

const StepTwo: React.FC = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-app-choose-your-system.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.copyContainer}>
        <div className={classes.leftContainer}>
          <RiShieldCheckLine size={24} />
        </div>
        <div>
          <Typography variant="h6">Step two: Explore and choose the best solar energy options for you</Typography>
          <Typography variant="body1">
            Our online portal lays out the different solar systems to achieve your wants and needs, which you can
            customize for your lifestyle.
            <br />
            <br />
            Chat with your solar advocate, let them walk you through the different options so you may make the best
            choice for your family or business.
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Img  fluid={data.image.childImageSharp.fluid} />
      </div>
    </div>
  );
};

export default StepTwo;
