import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { StickyBandit } from '@solvana/sticky-bandit';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const ThreeSteps: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Typography className={classes.threeStepsTitle} variant="h2">
          Your Online Solution to Go Solar in Three Steps
        </Typography>
        <StickyBandit
          cards={[
            { key: '1', component: <StepOne /> },
            { key: '2', component: <StepTwo /> },
            { key: '3', component: <StepThree /> },
          ]}
          configuration={{
            maxTranslateY: 50,
            minOpacityThreshold: 0.1,
            maxScaleDelta: 0.08,
            startOpacityChangeAtPercentOverlap: 0.3,
            endOpacityChangeAtPercentOverlap: 0.95,
            startScaleChangeAtPercentOverlap: 0.4,
            startTranslateYChangeAtPercentOverlap: 0.4,
          }}
        />
        
      </Container>
    </div>
    
  );
};

export default ThreeSteps;
