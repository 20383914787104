import React from 'react';

import { Button, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      textTransform: 'unset',
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
  }),
);

type Props = {
  title: string;
  className?: string;
  href?: string;
  size?: 'small' | 'medium' | 'large';
};

const SuccessButton: React.FC<Props> = ({ title, size, className, href }) => {
  const classes = useStyles();

  return (
    <Button
      size={size || 'small'}
      className={clsx(classes.root, { [className || '']: Boolean(className) })}
      disableElevation
      variant="contained"
      href={href}
    >
      {title}
    </Button>
  );
};

export default SuccessButton;
