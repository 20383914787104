import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';
import SuccessButton from '../../../components/SuccessButton';

const LearnMoreCTA: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-praxeum-get-solar-smart.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.copyContainer}>
        <Typography variant="h2">Interested in avoiding common pitfalls when it comes to going solar?</Typography>
        <Typography variant="body1">
          We developed a consumer guide to solar energy called Solar Praxeum&trade;. In minutes, you’ll learn everything
          you need to know about going solar, common pitfalls, and how to avoid them.
        </Typography>
        <SuccessButton title="Learn More" size="large" href={Routes.HowItWorks} />
      </div>

      <div className={classes.imgContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </div>
  );
};

export default LearnMoreCTA;
