import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors, linearPrimaryGradient } from '../../../theme/Colors';

const BG_SKY = '#c0def0';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(8, 0, 0),
      height: '93vh',
      boxSizing: 'border-box',
      backgroundColor: BG_SKY,
      [theme.breakpoints.down('md')]: {
        height: '100vh',
        paddingTop: theme.spacing(6),
      },
      overflow: 'hidden',
    },
    innerContainer: {
      '& h1': {
        padding: theme.spacing(3, 0, 1),
        fontSize: '76px',
        lineHeight: '76px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '60px',
          lineHeight: '60px',
          paddingTop: 0,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '50px',
          lineHeight: '50px',
        },
        background: linearPrimaryGradient,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
      '& h2': {
        fontSize: '28px',
        lineHeight: '32px',
      },
    },
    topContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexShrink: 0,
      flexDirection: 'column',
      padding: theme.spacing('3%', 0, 0, '5%'),
      zIndex: 2,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: theme.spacing(8),
        padding: theme.spacing('7%', 0, 0, '5%'),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3, 3, 1),
      },
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '7vh',
    },
    getStartedButton: {
      marginTop: theme.spacing(3),
      maxWidth: '280px',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        maxWidth: '220px',
      },
    },
    bottomContainer: {
      width: '100%',
      padding: 0,
      zIndex: 1,
      marginTop: theme.spacing(-22.5),
      '& img': {
        margin: 0,
        padding: 0,
      },
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(-3),
        padding: 0,
      },
    },
    infoCard: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(2, 3),
      position: 'absolute',
      bottom: '10vh',
      right: '3vh',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
        position: 'unset',
      },
      '& h6': {
        color: Colors.GreyFour,
        margin: 0,
        padding: 0,
      },
      '& ul': {
        margin: 0,
        padding: 0,
        '& li': {
          color: Colors.GreyFive,
          fontWeight: 900,
          margin: 0,
          padding: 0,
        },
      },
    },
    subtitle: {
      color: theme.palette.secondary.main,
      margin: theme.spacing(3, 0, 6),
      maxWidth: '525px',
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0, 3),
      },
    },
    button: {
      maxWidth: '300px',
      textTransform: 'unset',
    },
  }),
);
