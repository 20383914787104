import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import { RiShieldCheckLine } from 'react-icons/all';
import { useStyles } from '../common/styles';

const StepThree: React.FC = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-roof-mount-solar-installation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.copyContainer}>
        <div className={classes.leftContainer}>
          <RiShieldCheckLine size={24} />
        </div>
        <div>
          <Typography variant="h6">Step three: Installation and set up</Typography>
          <Typography variant="body1">
            After you’ve decided which solar system is best for your home or business and completed your purchase using
            our secure online portal, we’ll do the rest!
            <br />
            <br />
            To make this process as easy as possible for you, our team will handle working with your utility company,
            permitting, installation, inspections, and even warranty claims.
          </Typography>
        </div>
      </div>
      <Img fluid={data.image.childImageSharp.fluid} />
    </div>
  );
};

export default StepThree;
